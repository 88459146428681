<script setup>
import { onUnmounted, ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3'
import TwDropdown from '../TwDropdown.vue';
import TwDropdownItem from '../TwDropdownItem.vue';
import IconUserSecret from '~icons/fa6-solid/user-secret';

const dropdownOpen = ref(false);
const auth = usePage().props.auth;

const users = auth.user.impersonatable_users;

onUnmounted(
    router.on('start', () => {
        dropdownOpen.value = false; //user is navigating away, close the dropdown
    })
);

</script>
<template>
    <tw-dropdown v-if="users || auth.user.impersonating" class="inline">
        <template #icon>
            <icon-user-secret
                :class="
                    auth.user.impersonating ? 'text-rose-800 dark: dark:text-rose-600' : 'text-green-800 dark:text-green-600'
                "
                class="ml-3 mt-2 h-7 w-8 rounded-full"
            />
        </template>
        <tw-dropdown-item
            v-if="auth.user.impersonating"
            as="button"
            :href="route('leave-impersonation')"
            class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
            @click="dropdownOpen = false"
            >Stop Impersonating</tw-dropdown-item
        >
        <tw-dropdown-item
            v-for="user in users"
            v-else
            as="button"
            :href="'/impersonate/' + user.id"
            class="block w-full whitespace-nowrap px-4 py-2 text-left text-sm text-gray-700 hover:bg-secondary-600 hover:text-white dark:text-gray-200 dark:hover:text-black"
            @click="dropdownOpen = false"
            >{{ user.organization }}: {{ user.name }}</tw-dropdown-item
        >
    </tw-dropdown>
</template>
