<script setup>
import { computed, inject, onUnmounted, reactive } from 'vue';
import ArmsHeader from './Layout/ArmsHeader.vue';
import ArmsSidebar from './Layout/ArmsSidebar.vue';
import Loading from './Loading.vue';
import Notification from './Notification.vue';
import { router } from '@inertiajs/vue3';
import Honeybadger from '@honeybadger-io/js';

//import { useDarkMode } from '../Hooks/useDarkMode';
//const { darkMode } = useDarkMode();

//const showNotification = ref(false);

const notifications = reactive([]);

// const notification = reactive({
//     title: '',
//     message: '',
//     type: 'default',
//     timeout: 4000,
// });

const props = defineProps(['flash', 'auth']);

const darkMode = computed(() => {
    return props.auth.user.theme === 'dark';
});

Honeybadger.setContext({
    user_id: props.auth.user.id,
    user_name: props.auth.user.name,
});

// use Honeybadger.beforeNotify to prevent notifications for any /horizon requests
Honeybadger.beforeNotify((notice) => {
    if (notice.context.request.url.includes('/horizon')) {
        return false;
    }
});

const removeNotification = (timestamp) => {
    console.log('removing notification', timestamp);
    //remove notification with timestamp property from notifications array
    notifications.splice(
        notifications.findIndex((notification) => notification.timestamp === timestamp),
        1
    );
    //notifications.splice(index, 1);
};

const emitter = inject('emitter'); // Inject `emitter`

emitter.on('toast', (value) => {
    flashNotification(value);
});

onUnmounted(
    router.on('finish', () => {
        const flash = props.flash;
        flashNotification(flash);
    })
);

const flashNotification = (flash) => {
    if (flash) {
        console.log('launching toast');
        const notification = {
            title: '',
            message: '',
            type: 'default',
            timeout: 5000,
            show: true,
            timestamp: Date.now(),
        };

        if (typeof flash === 'string') {
            notification.type = 'default';
            notification.title = '';
            notification.message = flash;
            notification.timeout = 5000;
            notifications.push(Object.assign({}, notification));
        }

        if (typeof flash === 'object') {
            notification.type = flash?.type || 'default';
            notification.message = flash?.message || '';
            notification.title = flash?.title || '';
            notification.timeout = flash?.timeout || 5000;
            notifications.push(Object.assign({}, notification));
        }
    }
};

window.onbeforeprint = () => {
    if (darkMode.value) {
        document.getElementById('layout').classList.remove('dark');
    }
};
window.onafterprint = () => {
    if (darkMode.value) {
        document.getElementById('layout').classList.add('dark');
    }
};
</script>

<template>
    <div id="layout" class="font-roboto flex h-screen print:h-full bg-neutral-800 print:bg-white" :class="{ dark: darkMode }">
        <Loading />
        <template v-if="notifications.length > 0">
            <div v-for="(notification, index) in notifications" :key="notification.timestamp">
                <Transition
                    :show="true"
                    enter="transition-opacity duration-1000"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity duration-1000"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <Notification
                        :timeout="notification.timeout"
                        :title="notification.title"
                        :message="notification.message"
                        :type="notification.type"
                        :place="index"
                        :options="notification"
                        @hide="removeNotification(notification.timestamp)"
                    />
                </Transition>
            </div>
        </template>
        <arms-sidebar />

        <div class="flex flex-1 flex-col overflow-hidden">
            <arms-header />

            <main
                style="scrollbar-gutter: stable"
                class="flex-1 overflow-y-auto overflow-x-hidden bg-neutral-100 dark:bg-gray-700 dark:text-white"
                scroll-region
            >
                <div class="max-w-[1605px] px-6 py-2">
                    <slot />
                </div>
            </main>
        </div>
    </div>
</template>
