<script setup>
import { computed } from 'vue';
import SidebarLink from './SidebarLink.vue';
import { useSidebar } from '../../Hooks/useSidebar';
const { isOpen } = useSidebar();
// import IconUser from '~icons/heroicons-outline/user';
import IconUserGroup from '~icons/heroicons-outline/user-group';
import IconHome from '~icons/heroicons-outline/home';
//import IconCog from '~icons/heroicons-outline/cog';
import IconCodeBranch from '~icons/fa6-solid/code-branch';
import IconArrowRight from '~icons/fluent/document-table-arrow-right-20-regular';
import IconPlay from '~icons/fluent/play-20-regular';
import IconMegaphone from '~icons/fluent/megaphone-loud-20-regular';
//import IconResources from '~icons/icon-park-outline/guide-board';
import IconResources from '~icons/carbon/document-attachment';
import IconBuilding from '~icons/fluent/building-20-regular';
import translate from '../../Hooks/useLang';

import { useDarkMode } from '../../Hooks/useDarkMode';
 import { usePage } from '@inertiajs/vue3'
const { darkMode } = useDarkMode();

const auth = usePage().props.auth;

const isTexas = computed(() => {
    return auth.user.roles.includes('texas');
});
</script>

<template>
    <div class="flex print:hidden">
        <!-- Backdrop -->
        <div
            :class="isOpen ? 'block' : 'hidden'"
            class="fixed inset-0 z-20 bg-black opacity-50 transition-opacity lg:hidden"
            @click="isOpen = false"
        ></div>
        <!-- End Backdrop -->

        <div
            :class="isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
            class="fixed inset-y-0 left-0 z-30 w-[16.25rem] transform overflow-y-auto bg-neutral-700 transition duration-300 dark:bg-gradient-to-b dark:from-black dark:to-gray-700 lg:static lg:inset-0 lg:translate-x-0"
        >
            <div class="flex h-16 cursor-pointer items-center bg-neutral-700 dark:bg-black">
                <inertia-link href="/">
                    <div class="mx-6 inline pt-0 duration-200">
                        <img class="inline h-8" :src="darkMode ? '/favicon-32x32.png' : '/favicon-32x32.png'" alt="" />
                        <div class="inline pl-4 align-middle text-2xl font-light text-white">ARMS</div>
                    </div></inertia-link
                >
            </div>

            <div class="h-1 w-full bg-neutral-600 dark:bg-slate-600" />

            <nav class="mt-2">
                <sidebar-link :active="$page.component === 'Dashboard'" :href="route('dashboard')" :text="'Home'">
                    <icon-home />
                </sidebar-link>

                <!--                <sidebar-link
                    :active="$page.component === 'User/Settings'"
                    :href="route('users.show', auth.user.id)"
                    :text="'User'"
                >
                    <icon-user />
                </sidebar-link>-->

                <sidebar-link
                    :active="['Users', 'User'].includes($page.component)"
                    :href="route('users')"
                    :text="'Users'"
                >
                    <icon-user-group />
                </sidebar-link>

                <sidebar-link
                    :active="['Organizations', 'Organization'].includes($page.component)"
                    :href="route('organizations.index')"
                    text="Organizations"
                >
                    <icon-building />
                </sidebar-link>

                <sidebar-link
                    :active="['ApprovalPaths', 'ApprovalPath'].includes($page.component)"
                    :href="route('approval-paths.index')"
                    :text="translate('approvals')"
                >
                    <icon-code-branch />
                </sidebar-link>

                <sidebar-link
                    :active="['Authorities', 'Authority'].includes($page.component)"
                    :href="route('authorities.index')"
                    :text="translate('authorities')"
                >
                    <icon-play />
                </sidebar-link>

                <sidebar-link
                    :active="['Documents', 'Document'].includes($page.component)"
                    :href="route('documents')"
                    :text="isTexas ? 'Requests' : 'Documents'"
                >
                    <icon-arrow-right />
                </sidebar-link>

                <sidebar-link
                    v-if="!isTexas"
                    :active="['Announcements', 'Announcement'].includes($page.component)"
                    :href="route('announcements.index')"
                    text="Announcements"
                >
                    <icon-megaphone />
                </sidebar-link>

                <sidebar-link
                    v-if="!isTexas"
                    :active="['Resources', 'Resource'].includes($page.component)"
                    :href="route('resources.index')"
                    text="Resources"
                >
                    <icon-resources />
                </sidebar-link>
            </nav>
        </div>
    </div>
</template>
