<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3'
import { useSidebar } from '../../Hooks/useSidebar';
import UserDropdown from './UserDropdown.vue';
import SearchArms from './SearchArms.vue';
import NewPlus from './NewPlus.vue';
import ImpersonateDropdown from './ImpersonateDropdown.vue';
import AlertDropdown from './AlertDropdown.vue';
import WinClipboard from '../../Shared/WinClipboard.vue';
import IconAlert from '~icons/fa6-solid/triangle-exclamation';

const { isOpen } = useSidebar();
const title = computed(() => usePage().props.title);
const titleShort = computed(() => usePage().props.title_short);
const subtitle = computed(() => usePage().props.subtitle);
const docId = computed(() => usePage().props.doc_id);
const auth = usePage().props.auth;
const impersonating = Boolean(auth.user.impersonating);

const isTexas = computed(() => {
    return auth.user.roles.includes('texas');
});
</script>

<template>
    <div
        class="print:hidden flex h-16 items-center justify-between bg-neutral-200 px-6 dark:bg-black dark:from-black dark:to-gray-800 dark:text-white"
    >
        <div class="flex w-full max-w-[1605px]">
            <div class="flex w-full items-center">
                <button class="focus:outline-none dark:text-gray-500 lg:hidden" @click="isOpen = true">
                    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 6H20M4 12H20M4 18H11"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>

                <div class="mx-4 lg:mx-0">
                    <div class="inline text-lg sm:text-xl md:text-2xl">
                        <win-clipboard v-if="docId" class="mr-2" :text="docId" />
                        <span class="hidden sm:inline">{{ title }}</span>
                        <span class="inline sm:hidden">{{ titleShort ?? title }}</span>
                    </div>
                    <div
                        v-if="subtitle?.text"
                        class="block pl-2 text-xs font-semibold uppercase lg:inline"
                        :class="subtitle.type === 'danger' ? 'text-red-700' : ''"
                    >
                        <icon-alert v-if="subtitle.type === 'danger'" class="mb-0.5 hidden align-middle sm:inline" />
                        <component
                            :is="subtitle.href ? 'inertia-link' : 'div'"
                            :href="subtitle.href"
                            class="inline pl-1"
                            >{{ subtitle.text }}</component
                        >
                    </div>
                    <new-plus />

                    <div v-if="title === 'Documents'"></div>
                    <div v-if="impersonating" class="font-semibold uppercase text-red-800 dark: dark:text-rose-600">
                        !!! Impersonating {{ auth.user.name }} ({{ auth.user.organization.name }}) !!!
                    </div>
                </div>

                <div class="flex-grow">
                    <div class="float-right mr-5 inline w-36">
                        <search-arms />
                    </div>
                </div>
            </div>

            <div class="flex items-center">
                <!--            <button
                class="mx-4 flex text-gray-800 focus:outline-none dark:text-white"
                @click="darkMode = !darkMode"
            >
                <icon-moon class="h-6 w-6" />
            </button>-->

                <div class="relative">
                    <user-dropdown />
                </div>
                <alert-dropdown v-if="!isTexas" />
                <impersonate-dropdown />
            </div>
        </div>
    </div>

    <div class="h-1 w-full bg-gradient-to-r from-stone-900 to-stone-300 dark:from-slate-600 dark:to-slate-200" />
</template>
