<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3'
import IconPlus from '~icons/fa6-solid/plus';

const newHref = computed(() => {
    return usePage().props.newHref; //this can change page-to-page, so put it in a computed()
});
</script>
<template>
    <div v-if="newHref" class="inline pl-3 align-top">
        <inertia-link :href="newHref">
            <icon-plus class="mt-1 inline hover:text-gray-500" />
        </inertia-link>
    </div>
</template>
